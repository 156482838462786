<template>
  <div style="height: calc(100vh - 60px)" class="list-search">
    <div
      class="flex items-center gap-2 pt-[35px] !pb-4 !px-4 bg-ems-gray900 border-b-1 border-solid border-ems-tag1"
    >
      <cds-feather-icons
        type="chevron-left"
        size="24"
        class="text-ems-gray300 cursor-pointer"
        @click="backToPrevious"
      />
      <h1 class="font-semibold text-base text-white">
        {{ t('search.title') }}
      </h1>
    </div>
    <div class="!p-4 h-[calc(100%-76px)]">
      <div class="!p-5 bg-ems-gray800 h-full">
        <h2 class="font-semibold text-base text-white">
          {{ t('search.suitable-obj') }}
        </h2>
        <div class="h-[1px] w-full bg-ems-gray700 mt-6"></div>
        <div
          v-if="listUsers.length === 0 && isLoading"
          class="flex !gap-5 mt-6"
        >
          <CustomSkeleton />
          <CustomSkeleton />
        </div>

        <Empty
          v-else-if="listUsers.length === 0"
          :description="t('group-management.list-object-empty')"
          class="mt-[60px]"
        />
        <div v-else class="pt-6 pr-2 overflow-y-auto h-[calc(100%-49px)]">
          <div class="h-fit grid grid-cols-2 gap-6 2xl:grid-cols-3">
            <div
              class="rounded-[8px] !p-5 bg-ems-tag1 flex items-center !gap-5 max-h-[200px] overflow-hidden"
              v-for="(item, idx) in listUsers"
              :key="item.id"
            >
              <img
                :src="
                  !item.imagesAvatar
                    ? require('@/static/img/no-img.png')
                    : item.imagesAvatar
                "
                alt="avatar"
                width="120"
                height="120"
                class="w-[120px] h-[120px] object-cover"
              />
              <div class="flex flex-col gap-3">
                <span class="font-semibold text-lg text-ems-gray100">{{
                  item.name ? item.name : item.id
                }}</span>
                <div class="flex items-center flex-wrap gap-3">
                  <span
                    v-if="item.ioGroups && item.ioGroups.length > 0"
                    class="group-name overflow-hidden overflow-ellipsis max-h-[30px] max-w-[200px]"
                    :class="getColor(listGroups[item.ioGroups[0]].warningLevel)"
                    @click="onClickGroup(item.ioGroups[0])"
                    >{{
                      listGroups[item.ioGroups[0]]
                        ? listGroups[item.ioGroups[0]].name
                        : ''
                    }}</span
                  >
                  <span
                    v-if="item.ioGroups && item.ioGroups.length > 1"
                    class="group-name overflow-hidden overflow-ellipsis max-h-[30px] max-w-[200px]"
                    :class="getColor(listGroups[item.ioGroups[1]].warningLevel)"
                    @click="onClickGroup(item.ioGroups[1])"
                    >{{
                      listGroups[item.ioGroups[1]]
                        ? listGroups[item.ioGroups[1]].name
                        : ''
                    }}</span
                  >
                  <a-popover
                    placement="top"
                    overlay-class-name="popup-list-group"
                    v-if="item.ioGroups && item.ioGroups.length > 2"
                  >
                    <template #content>
                      <span
                        class="px-[10px] py-[3px] text-ems-gray100 block rounded-[4px] w-[fit-content] cursor-pointer hover:opacity-80 whitespace-nowrap inline-block mr-3 mb-3"
                        v-for="(idGroup, idx) in item.ioGroups"
                        :key="idGroup"
                        :class="getColor(listGroups[idGroup].warningLevel)"
                        @click="onClickGroup(idGroup)"
                      >
                        {{
                          listGroups[idGroup] ? listGroups[idGroup].name : ''
                        }}
                      </span>
                    </template>
                    <span
                      class="px-[10px] py-[3px] text-ems-gray100 block rounded-[4px] w-[fit-content] bg-ems-gray500"
                    >
                      ...
                    </span>
                  </a-popover>
                </div>
              </div>
            </div>
            <CustomSkeleton v-if="isLoading" />
          </div>
          <div
            class="w-full flex justify-center mt-3"
            v-if="totalIO > 6 * (page + 1)"
          >
            <a-button
              type="primary"
              class="!text-ems-main2 !border-ems-main2 text-base font-semibold"
              ghost
              @click="viewMore"
              >{{ t('common.view-more') }}</a-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { i18n } from '@/main';
import { reactive, onMounted, ref, computed } from 'vue';
import { useStore } from 'vuex';
import { WARNING_LEVEL } from '@/config/Constant.js';
import { useRouter, useRoute } from 'vue-router';
import CustomSkeleton from './CustomSkeleton.vue';
import Empty from '@/components/empty/index.vue';

const { t } = i18n.global;
const { state, dispatch } = useStore();
const listUsers = computed(() => state.ioGroup.listSearchAdvanced);
const listGroups = computed(() => {
  let groups = {};
  state.ioGroup.listGroup.map((el) => {
    groups[el.id] = el;
  });
  return groups;
});
const totalIO = computed(() => state.ioGroup.totalSearchAdvanced);
const getColor = computed(() => (lv) => {
  let color = WARNING_LEVEL[lv - 1].color;
  return color;
});
const isLoading = ref(false);
const router = useRouter();
const route = useRoute();
const page = ref(0);
const params = reactive({});
onMounted(async () => {
  isLoading.value = true;
  if (route.query) {
    if (route.query.groupName) {
      params.groupName = route.query.groupName;
    }
    if (route.query.warningLvl) {
      params.warningLvl = route.query.warningLvl;
    }
    if (route.query.ioName) {
      params.ioName = route.query.ioName;
    }
    if (route.query.img) {
      params.imgUrl = route.query.img;
    }
  }
  await dispatch('ioGroup/getTreeGroup');
  await dispatch('ioGroup/groupSearchAdvanced', {
    payload: { ...params, size: 6, page: page.value },
    isViewMore: false,
  });
  isLoading.value = false;
});
const viewMore = async () => {
  isLoading.value = true;
  page.value++;
  await dispatch('ioGroup/groupSearchAdvanced', {
    payload: { ...params, size: 6, page: page.value },
    isViewMore: true,
  });
  isLoading.value = false;
};
const backToPrevious = () => {
  router.push({
    path: '/io-management/group-management',
  });
};
const onClickGroup = (idGroup) => {
  router.push({
    path: '/io-management/group-management',
    query: { idGroup: listGroups.value[idGroup].id, action: 3 },
  });
};
</script>
<style lang="scss">
.list-search .group-name {
  border-radius: 4px;
  cursor: pointer;
  display: block;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
  --tw-text-opacity: 1;
  color: rgba(233, 233, 233, var(--tw-text-opacity));
  white-space: nowrap;
  width: fit-content;
}
.list-search .group-name:hover {
  opacity: 0.8;
}
.popup-list-group .ant-popover-arrow {
  display: none;
}
.popup-list-group .ant-popover-inner {
  box-shadow: 0 4px 84px 0 rgba(255, 255, 255, 0.1);
}
</style>
